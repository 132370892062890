declare global {
    interface Window {
        infinario: {
            start: (config: { customer: string }) => void;
            track: (name: string, config?: object) => void;
            update: (attributes: object) => void;
        };
    }
}

export const exponea = {
    status: {
        actionTaken: 'action_taken',
        screenShown: 'screen_shown'
    },
    action: {
        calendar: 'calendar',
        expand: 'expand',
        filter: 'filter',
        search: 'search',
        groupBy: 'groupBy',
        addPoi: 'add_poi',
        addManualExpense: 'add_manual_expense',
        addDestination: 'add_destination',
        createFuelCard: 'create_fuel_card',
        createTachographCard: 'create_tachograph_card',
        createUser: 'create_user',
        createTrailer: 'create_trailer',
        hideDemo: 'hide_demo',
        showDemo: 'show_demo',
        showNewTransports: 'show_new_transports',
        showDelay: 'show_delay',
        showFuelStations: 'show_fuel_stations',
        selectLanguage: 'select_language',
        saveRoute: 'save_route',
        cancelRoute: 'cancel_route',
        addDestinationPointToRoute: 'add_destination_point_to_route',
        goToTransportDetail: 'go_to_transport_detail',
        openNewMaintenance: 'open_new_maintenance',
        submitNewMaintenance: 'submit_new_maintenance',
        openDuplicateMaintenance: 'open_duplicate_maintenance',
        submitDuplicateMaintenance: 'create_duplicate_maintenance',
        filterDueMaintenance: 'filter_due_maintenance',
        accept: 'accept',
        decline: 'decline',
        propose_price: 'propose_price',
        send_proposed_price: 'send_proposed_price',
        download_order: 'download_order',
        back_to_ew_office: 'back_to_ew_office'
    },
    module: {
        clientLogin: 'client-login',
        userSettings: 'user-settings',
        vehicleDetail: 'vehicle-detail',
        maintenanceDetail: 'maintenance-detail',
        mapFuelPanel: 'map-fuel-panel',
        fuelStationDetail: 'fuel-station-detail',
        trackingTableView: 'tracking_table-view',
        schedulingRouteOverview: 'scheduling_orders',
        schedulingPlanner: 'scheduling_planner',
        schedulingDispatcherBoard: 'scheduling_dispatcher-board',
        settingsFleet: 'settings_fleet',
        settingsFuelCards: 'settings_fuel-cards',
        settingsCustomPlaces: 'settings_custom-places',
        settingsTachographCards: 'settings_tachograph-cards',
        settingsUsers: 'settings_users',
        settingsCustomerApi: 'settings_customper-api',
        settingsDriverCardsRemoteMemory: 'settings_remote-memory-driver-cards',
        settingsVehiclesRemoteMemory: 'settings_remote-memory-vehicles',
        statisticsAetr: 'statistics_aetr',
        statisticsCompanyProfile: 'statistics_company-profile',
        statisticsExpenses: 'statistics_expenses',
        statisticsFuelConsumption: 'statistics_fuel-consumption',
        statisticsJourneysActivity: 'statistics_journeys-activity',
        statisticsMaintenance: 'statistics_maintenance',
        statisticsStatisticReport: 'statistics_statistic-report'
    }
};

export enum StatusToExponeaStatus {
    'stop',
    'drive',
    'pause'
}

export class ExponeaLogic {
    initialize(customer: string) {
        if (!localStorage.identity && window.infinario) {
            window.infinario.start({
                customer
            });
        }
    }

    pageview(page: string) {
        if (!localStorage.identity && window.infinario) {
            const hash = page.slice(2, page.indexOf('?') === -1 ? page.length : page.indexOf('?')).replace('/', '_');
            const event = hash.substring(0, hash.indexOf('/') === -1 ? hash.length : hash.indexOf('/'));
            window.infinario.track(event, {
                status: exponea.status.screenShown,
                url: page,
                src: 'menu'
            });
        }
    }

    trackEvent(event: string, config: object) {
        if (!localStorage.identity && window.infinario) {
            window.infinario.track(event, config);
        }
    }

    updateAttributes(attributes: object) {
        if (!localStorage.identity && window.infinario) {
            window.infinario.update(attributes);
        }
    }
}
