import moment from 'moment';
import eio from 'engine.io-client';
import { InitializeOptions } from 'react-ga';

import { DATE_FORMAT, DATE_TIME_FORMAT } from 'domain-constants';
import { ContactListListTypeEnum, ReadOnlyUser } from 'generated/new-main';
import { ExpenseSource, ExpenseType } from 'generated/graphql';
import { AddressIdentification, VehicleIdentification, Theme } from 'common/components/Settings';
import { VehicleDelayedFilterCode, VehicleStatusFilterCode } from 'common/model/tracking';
import { ExpenseState, Aggregator } from 'logic/statistics/statistics-expenses';
import { SmartBarFilter } from 'modules/tracking/TrackingModule';
import { Role } from './logic/auth';
import { version } from '../package.json';
import { LayerTypes } from 'modules/map/MapModule';
import { FleetActive, FleetType } from 'modules/management/modules/fleet/FleetModule';
import { FuelStatsGroupByTimespan, FuelStatsGroupByEntity } from 'generated/backend-api';

export const confDefault = {
    version,
    debug: true,
    testMode: false,
    useDFFTransports: false,
    settings: {
        lang: 'en',
        demoMode: {
            active: false,
            roles: Object.values(Role).filter(
                r => ![Role.PM_R, Role.CAC_R, Role.OAC_R, Role.PUESC, Role.DM_RD].includes(r)
            )
        },
        addressIdentification: AddressIdentification.Address,
        vehicleIdentification: VehicleIdentification.RegistrationNumber,
        warningSwitzerland: true,
        driverBehaviorCoachAvailable: false,
        plannerOldAvailable: false,
        theme: Theme.Dark,
        themes: [
            {
                name: Theme.Dark,
                path: `./css/main.css${process.env.NODE_ENV === 'production' ? '?v=997587' : ''}`
            },
            {
                name: Theme.Light,
                path: `./css/main-light.css${process.env.NODE_ENV === 'production' ? '?v=997587' : ''}`
            }
        ],
        map: {
            style: LayerTypes.ROADMAP,
            traffic: false,
            vehicleClusteringEnabled: true,
            parking: false,
            fuel: true,
            wash: false,
            poi: false
        },
        tracking: {
            rolled: false,
            expanded: false,
            filter: {
                delay: VehicleDelayedFilterCode.ALL_ETAS,
                status: VehicleStatusFilterCode.ALL_VEHICLE_STATUSES,
                monitoredObjectGroupsChecked: [] as string[]
            },
            smartFilter: {
                active: undefined as SmartBarFilter | undefined,
                fuelLow: {
                    tankSizeLimit: 0.333,
                    vehicleLimit: 300,
                    lightVehicleLimit: 15
                }
            },
            vehicles: undefined as string[] | undefined,
            batteryVoltageLowLimit: {
                vehicle: 22,
                lightVehicle: 12
            }
        },
        statistics: {
            journeysActivity: {
                filter: {
                    vehicle: '' as string,
                    driver: '' as string,
                    trailer: '' as string,
                    dateRange: {
                        start: moment().subtract(7, 'days').startOf('day').format(DATE_TIME_FORMAT),
                        end: moment().endOf('day').format(DATE_TIME_FORMAT)
                    }
                },
                expanded: false,
                disabledCoachPromo: false
            },
            fuelConsumption: {
                filter: {
                    vehicles: [] as string[],
                    drivers: [] as string[],
                    dateRange: {
                        start: moment().subtract(7, 'days').format(DATE_FORMAT),
                        end: moment().format(DATE_FORMAT)
                    },
                    groupByTimespan: FuelStatsGroupByTimespan.Day,
                    groupByEntity: FuelStatsGroupByEntity.Vehicle,
                    shortActivity: true,
                    shortActivityLimit: 0.1,
                    fuelSuspiciousActivity: true,
                    fuelSuspiciousActivityLimit: 100
                }
            },
            aetr: {
                filter: {
                    vehicles: [] as string[],
                    drivers: [] as string[]
                }
            },
            expenses: {
                filter: {
                    vehicles: [] as string[],
                    type: '' as ExpenseType,
                    payment: '' as ExpenseState,
                    source: '' as ExpenseSource,
                    dateRange: {
                        start: moment().subtract(7, 'days').format(DATE_FORMAT),
                        end: moment().format(DATE_FORMAT)
                    },
                    aggregator: Aggregator.DATE
                }
            },
            maintenance: {
                filter: {
                    vehicles: [] as string[],
                    trailers: [] as string[],
                    drivers: [] as string[],
                    taskTypes: [] as string[],
                    intervals: [] as string[],
                    status: [] as string[],
                    dueDateGte: '' as string,
                    dueDateLte: '' as string
                }
            },
            coldChain: {
                filter: {
                    vehicles: [] as string[],
                    trailers: [] as string[],
                    profiles: [] as string[],
                    dateRange: {
                        start: moment().subtract(1, 'day').add(1, 'second').format(DATE_TIME_FORMAT),
                        end: moment().format(DATE_TIME_FORMAT)
                    },
                    alerts: {
                        alerting: true,
                        resolved: true
                    }
                }
            }
        },
        management: {
            users: {
                // filter: UserFilter.Users,
                lastCreated: undefined as ReadOnlyUser | undefined,
                lastCreatedAt: undefined as string | undefined
            },
            fleet: {
                filter: {
                    fleetActive: FleetActive.ACTIVE as FleetActive | undefined,
                    fleetType: undefined as FleetType | undefined
                }
            },
            contactList: {
                filter: {
                    types: [] as ContactListListTypeEnum[],
                    countries: [] as number[]
                }
            }
        },
        scheduling: {
            routeOverview: {
                filter: {
                    vehicles: [] as string[],
                    drivers: [] as string[],
                    transportState: [] as string[],
                    dateRange: {
                        start: moment().subtract(7, 'days').format(DATE_FORMAT),
                        end: moment().add(7, 'days').format(DATE_FORMAT)
                    }
                }
            }
        },
        dispatcherBoard: {
            eta: true,
            break: true,
            expanded: false,
            startDate: moment().subtract(1, 'day').format(DATE_FORMAT)
        },
        planner: {
            transport: undefined,
            possibleTransports: undefined,
            tollCostByType: undefined,
            defaultPuescActive: undefined,
            hideAetrBreaksAlert: undefined
        },
        driverBehavior: {
            date: undefined,
            trucks: {
                comparedDriverId: undefined,
                comparedDate: undefined
            }
        }
    },
    lang: 'en',
    langs: [
        'bg',
        'cs',
        'da',
        'de',
        'en',
        'es',
        'et',
        'fi',
        'fr',
        'ga', // irish, not supported by google maps
        'el',
        'hr',
        'hu',
        'it',
        'lt',
        'lv',
        'mt', // maltese, not supported by google maps
        'nl',
        'pl',
        'pt',
        'ro',
        'ru',
        'sk',
        'sl',
        'sv',
        'tr',
        'uk'
    ],
    langsDocs: [
        'en',
        'sk',
        'pl',
        'cs',
        'ro',
        'de',
        'es',
        'pt',
        { hu: ['puesc', 'ewMissionApp'] },
        { fr: ['ewMissionApp'] },
        { bg: ['ewMissionApp'] },
        { tr: ['ewMissionApp'] }
    ],
    visibleModulesIds: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 18, 19, 20, 21, 22, 26],
    auth: {
        keycloak: {
            url: 'http://ew-sso-uat.westeurope.cloudapp.azure.com/auth',
            realm: 'eurowag',
            'ssl-required': 'none',
            resource: 'telematics-web',
            'public-client': true,
            'use-resource-role-mappings': true,
            'confidential-port': 0,
            clientId: 'telematics-web'
        },
        tokenUserIdKey: 'uid',
        tokenUserNameKey: 'name',
        tokenUserEmailKey: 'email',
        tokenLocaleKey: 'locale',
        mockedSSO: {
            use: true as boolean,
            token: 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI0OHNnNjljbExWaXB5b2x3LVpiNTNhNzliNXk0dUJkRFhUUEw3NVMwRkVzIn0.eyJqdGkiOiIyNWNjYTJlNi00OWQzLTQ5NWEtYWZiNC0xZjNkMjU1Y2MzM2IiLCJleHAiOjE1ODE1MjM0MDksIm5iZiI6MCwiaWF0IjoxNTgxNTIzMTA5LCJpc3MiOiJodHRwczovL2xvZ2luLXRlc3QuZXVyb3dhZy5jb20vYXV0aC9yZWFsbXMvZXVyb3dhZyIsInN1YiI6ImMzYTEzNzNiLTJiY2EtNGIzOC04MmI5LTIzN2IxZTI4ZDBmNiIsInR5cCI6IkJlYXJlciIsImF6cCI6InRlbGVtYXRpY3Mtd2ViIiwibm9uY2UiOiIyNTM1MTQ3YS03Y2Y5LTQwNjctOTE3MC1mNzAzYjVhMmM5OTIiLCJhdXRoX3RpbWUiOjE1ODE1MjMxMDYsInNlc3Npb25fc3RhdGUiOiJkZmRmYTQyOS04N2NjLTQxMjktYjEwYS1mYzQzZmExYmFiZmQiLCJhY3IiOiIxIiwic2NvcGUiOiJvcGVuaWQgdGJyIGV3IGNybSIsImJpbmFyeVJpZ2h0cyI6IlRMTTAwOkZGIFRMTTAxOkZGIFRMTTAyOkZGIFRMTTAzOkZGIFRMTTA0OkZGIFRMTTA1OkZGIFRMTTA2OkZGIFRMTTA3OkZGIFRMTTA4OkZGIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInVzciI6Inh1ZGVybWFuIiwibmFtZSI6ImRhbmllbCB1ZGVybWFuIiwiY29udGFjdE51bWJlciI6IjMxMDM0IiwibG9jYWxlIjoic2siLCJhY2NvdW50TnVtYmVyIjoiMzEwMzQiLCJnaXZlbl9uYW1lIjoiZGFuaWVsIiwiZmFtaWx5X25hbWUiOiJ1ZGVybWFuIiwiZW1haWwiOiJ4LnVkZXJtYW5AZ21haWwuY29tIn0.c2Bq53jlUxD7ZzXIc0Fh2JOQW6PZAZTAK7WJjbSqtLwzciWaAusepKLxm-5hgiBnHk_rl0b4Vlmq6g9raucR1A22WufsTolSECKLtAcr5djKr6zHsbLWNa_GzDkWUD4j9x9MfwpSolbtH5fGAIPAE2hndb1r9dyfV_RGlqrWzXZbE8DaHOODnhrg7wEw6WFeEmj1HlbZGt0Ns_5TOIH6739z7Jobmxk-KUWtwIfKV4CBSAya6xnswjEji1YYpRZg9-liKBlwExBYnLDchNEMjyTVbDF5Srrz6YrLiPVeU-VfRxDcscu6AKeNchgNgOM4W6SFzUypa3Zd5DTBV8ujsg'
        }
    },
    geocoding: {
        apiKey: ''
    },
    googleAnalytics: {
        trackingCode: 'UA-146502010-1',
        options: {} as InitializeOptions
    },
    notification: {
        sendUri: 'https://telematics-dev.wag-test.local/notification/notification',
        engineio: {
            uri: 'http://telematics-dev-aks-front.westeurope.cloudapp.azure.com',
            opts: {} as eio.SocketOptions
        }
        // socketio: {
        //     uri: 'http://telematics-dev-aks-front.westeurope.cloudapp.azure.com',
        //     opts: {} as SocketIOClient.ConnectOpts
        // },
        // sseUri:
        //     'https://telematics-dev.wl/notification/sse'
    },
    notificationDFF: {
        sendUri: 'https://dff-dev.wag-test.local/notification/notification',
        engineio: {
            uri: 'https://dff-dev.wag-test.local',
            opts: {} as eio.SocketOptions
        }
    },
    messaging: {
        uri: 'https://telematics-dev.wag-test.local/notification/messaging'
    },
    api: {
        notification: {
            basePath: 'https://telematics-dev.wag-test.local/notification/api'
        },
        main: {
            basePath: 'https://telematics-dev.wag-test.local/main-data/api'
        },
        geocoding: {
            basePath: 'https://telematics-dev.wag-test.local/geocoding/api'
        },
        newMain: {
            basePath: 'http://localhost:22019'
        },
        customer: {
            basePath: 'http://localhost:22019/api/v1',
            docs: 'http://localhost:22019/openapi.json'
        },
        backend: {
            basePath: 'http://localhost:22022/api/v1'
        },
        transport: {
            basePath: 'https://dff-dev.wag-test.local/transports/api'
        },
        cgiPartnerModule: {
            basePath: 'https://telematics-dev.wag-test.local/tp'
        },
        routing: {
            basePath: 'http://localhost:8887/api',
            // basePath: 'http://192.168.111.38:22023/api',
            service: 'sygic', // 'here'
            trafficMode: true
        }
    },
    graphQL: {
        url: 'https://telematics-dev.wag-test.local/api/graphql'
    },
    places: {
        url: 'https://telematics-dev.wag-test.local/api'
    },
    map: {
        initBounds: [
            { lat: 48.73946, lng: 19.15349 },
            { lat: 54.52506479679937, lng: 18.535566278118853 },
            { lat: 38.695927, lng: -9.194453 }
        ],
        vehicles: {
            vehicleCenterDistance: 2000
        }
    },
    docs: {
        path: '/docs/'
    },
    tracking: {},
    routePlanner: {},
    statisticsAetrReading: {},
    statisticsFuelConsumption: {},
    statisticsExpenses: {},
    journeysActivityStatistics: {},
    dispatcherKitDriverCardsRemoteMemory: {},
    dispatcherKitVehiclesRemoteMemory: {},
    schedulingRouteOverview: {},
    poi: {},
    expence: {},
    filter: {},
    superadminLogic: {}
};

export type Conf = typeof confDefault;
